@import "../../../styles/colors.scss";
@import "../../../styles/breakpoints.scss";

.container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}

.table {
  table-layout: fixed;
  width: 90%;
  border-collapse: collapse;
  box-shadow: 0 2px 32px 0 #0000001a;
  border-bottom: 2px solid $green;
}

.thead,
.tfoot {
  text-align: left;
  color: $green;
}

.tfoot tr {
  .th,
  td {
    border-top: 1px solid $green;
  }
}

.row {
  background: $green;
  color: $white;
}

.tr {
  border-bottom: 1px solid $lightGray;
}

.tr:nth-child(even) {
  background: $lightestGray;
}

.th,
.td {
  padding: 16px;
  text-transform: capitalize;
}

.th,
.td {
  &:first-of-type {
    width: 70%;
    border-radius: 10px 0 0 0;
    @media (max-width: 820px) {
      width: 40%;
    }
  }
  &:last-of-type {
    border-radius: 0 10px 0 0;
  }
}
