@import "../../../styles/colors.scss";

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.overlay {
  position: fixed;
  z-index: 9;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  animation: fadein 300ms;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  background-color: $white;
  border: 1px solid $gray;
  border-radius: 20px;
  padding: 1.25rem;
  position: relative;
  height: auto;
  max-width: 420px;
  width: 100%;
}

.header {
  position: relative;
  display: flex;
  justify-content: center;
  padding: 1rem;
}

.close {
  position: absolute;
  top: 0;
  right: 0;
  background: none;
  border: none;
}
