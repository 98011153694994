@import "../../../styles/breakpoints.scss";
@import "../../../styles/colors.scss";

.section {
  display: grid;
  margin: 0 100px;
  padding-bottom: 50px;
  grid-gap: 10px;
  grid-template-columns: 30% 70%;
  padding-bottom: 100px;
  padding-top: 2rem;
  border-top: 1px solid #dee3e3;

  @media (max-width: $desktop) {
    margin: 0 50px;
  }

  @media (max-width: $mobile-landscape) {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: 0;
  }
}

.columnOne {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: auto;
  padding: 0 20px;
  border-right: 1px solid $lightGray;
}

.columnTwo {
  grid-column-start: 2;
  padding: 0 20px;
}

.progress {
  display: flex;
  justify-content: center;
  width: 90%;
  margin: 15px 0 25px;
}

.wrapper {
  width: 150px;
  height: 150px;
}

.percentText {
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  padding-left: 10px;
  font-family: "Nanum Myeongjo", serif;
  color: $green;
}

.total {
  display: block;
  font-size: 3rem;
}
