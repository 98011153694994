@import "../../styles/colors.scss";

.count {
  color: $green;
  font-size: 18px;
}

.bold {
  font-weight: 600;
}

.link {
  color: $green;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}
