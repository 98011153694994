@import "../../styles/colors.scss";
@import "../../styles/breakpoints.scss";

.button {
  display: flex;
  align-items: center;
  background: none;
  border: none;
  color: $green;
  font-weight: 600;
  font-size: 1rem;
  padding-right: 10px;

  img {
    @media (max-width: $mobile-landscape) {
      width: 40px;
    }
  }
}

.icon {
  display: flex;
  align-items: center;
  padding: 5px;
}
