@import "../../../styles/colors.scss";

.wrapper {
  margin-bottom: 2rem;
}

.input {
  height: 50px;
  width: 100%;
  margin: 10px 0;
  border-radius: 4px;
  border: 1px solid $green;
  text-indent: 10px;
}

.label {
  color: $green;
  font-weight: 600;
}
