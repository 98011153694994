@import "../../../styles/colors.scss";

.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
}

.loading {
  display: flex;
  justify-content: center;
}

.loading::after {
  content: "";
  width: 50px;
  height: 50px;
  border: 10px solid $tan;
  border-top-color: $green;
  border-radius: 50%;
  animation: loading 1s linear infinite;
}

@keyframes loading {
  to {
    transform: rotate(1turn);
  }
}
