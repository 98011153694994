@import "../../../styles/colors.scss";

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 90%;
}

.progress {
  padding: 10px 0;
  text-transform: capitalize;
  line-height: 2rem;
  @media (max-width: 820px) {
    line-height: normal;
  }
}

.perc {
  color: $green;
  font-weight: 600;
}

.text {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
