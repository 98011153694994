@import "../../../styles/colors.scss";
@import "../../../styles/breakpoints.scss";

.container {
  margin: 50px 100px;
  padding-bottom: 100px;

  @media (max-width: $desktop) {
    margin: 0 25px;
  }
}

.header {
  display: grid;
  align-items: center;
  color: $green;
  border-bottom: 2px solid $green;
  margin-bottom: 2rem;
  text-align: center;

  h2 {
    font-family: "Nanum Myeongjo", serif;
    text-transform: capitalize;
    font-size: 22px;
    font-weight: 400;
    letter-spacing: 8px;
    grid-column-start: 1;
    grid-row-start: 1;
    justify-self: center;
  }

  @media (max-width: $mobile-landscape) {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-bottom: 10px;
  }
}

.count {
  grid-column-start: 1;
  grid-row-start: 1;
  justify-self: right;
}

.listContainer {
  -webkit-column-count: 3; /* Chrome, Safari, Opera */
  -moz-column-count: 3; /* Firefox */
  column-count: 3;
  column-fill: balance;

  @media (max-width: $desktop) {
    -webkit-column-count: 2; /* Chrome, Safari, Opera */
    -moz-column-count: 2; /* Firefox */
    column-count: 2;
  }

  @media (max-width: $mobile-landscape) {
    -webkit-column-count: 1; /* Chrome, Safari, Opera */
    -moz-column-count: 1; /* Firefox */
    column-count: 1;
  }
}

.buttonWrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background: white;
  z-index: 1;
  display: flex;
  justify-content: flex-end;

  .saveButton {
    margin-right: 2rem;
  }
}

.noResults {
  display: flex;
  justify-content: center;
  color: $darkGray;
}
