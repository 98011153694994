@import "../../../styles/colors.scss";
@import "../../../styles/breakpoints.scss";

.container {
  position: relative;
  display: flex;
  align-items: center;
  height: 50px;
  padding: 0 0.5rem;
  margin: 10px 0;
  border: 1px solid $green;
  justify-self: center;
  border-radius: 4px;

  &.isOpen {
    .list {
      display: block;
    }

    .caret {
      transform: rotateY(0deg) rotate(180deg);
    }
  }
}

.button {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  text-align: left;
  white-space: nowrap;
  background: none;
  border: none;
  color: $black;
}

.iconContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  align-self: stretch;
}

.clearIcon {
  all: unset;
  display: flex;
  align-items: center;
  border-right: 1px solid gray;
}

.title {
  font-size: 18px;
  text-transform: capitalize;
  width: 90%;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-top: 10px;
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 5px;

  svg {
    -webkit-transition: 0.1s linear;
    -moz-transition: 0.1s linear;
    -o-transition: 0.1s linear;
    transition: 0.1s linear;
  }

  &.isOpen {
    svg {
      transform: rotate(-180deg);
      -webkit-transform: rotate(-180deg);
    }
  }
}

.list {
  position: absolute;
  z-index: 1;
  background: $white;
  max-height: 183px;
  overflow: auto;
  top: 50px;
  left: -1px;
  width: 100%;
  margin: 0;
  padding: 0;
  border-top: 0;
  display: none;
  border: 1px solid $green;
  list-style: none;
}

.listItem {
  height: 45px;
  display: flex;
  padding-left: 10px;
  align-items: center;
  text-align: left;
  border-bottom: 1px solid $green;
  color: $black;
  text-transform: capitalize;
  cursor: pointer;

  &.selected {
    background: $tan;
  }

  &.highlighted {
    background: $lightestGray;
  }

  &:last-child {
    border-bottom: none;
  }
}

.label {
  color: $green;
  font-size: 18px;

  &.isOpen {
    font-size: inherit;
    position: absolute;
    top: 5px;
    color: $green;
    font-weight: 600;
  }
}
