@import "../../../styles/colors.scss";
@import "../../../styles/breakpoints.scss";

.mapContainer {
  position: relative;
  @media (max-width: $desktop) {
    margin: 2rem 0 3rem 0;
  }
}

.tooltip {
  position: absolute;
  padding: 2px;
  font-size: 12px;
  background: $white;
  border: 1px solid $black;
  opacity: 0;
}

.state {
  stroke: $white;
  stroke-width: 1;
}
