@import "../../styles/colors.scss";
@import "../../styles/breakpoints.scss";

.nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 1;
  color: $green;
}

.content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 3.125rem;
  padding: 0 2.5rem;
  background: $white;

  @media (max-width: $tablet) {
    padding: 0 1rem;
  }
}

.title {
  font-weight: 600;
  font-size: 1rem;

  .img {
    padding-right: 0.5rem;
  }

  &:hover {
    text-decoration: underline;
  }

  a {
    height: 100%;
    width: auto;
    display: flex;
    align-items: center;
    text-decoration: none;
    color: inherit;
  }
}

.header {
  @media (max-width: $mobile-landscape) {
    margin-left: 0;
    justify-content: center;
    width: 32%;
  }
}

.right {
  display: flex;
  align-items: center;
  color: $green;

  @media (max-width: $mobile-landscape) {
    justify-content: flex-end;
    width: 70%;
  }
}

.socialLinks {
  margin-right: 1rem;
  display: flex;
  align-items: center;

  svg {
    width: 1.4rem;
    height: 1.4rem;
  }

  a {
    color: $green;
    padding: 5px 10px 0 10px;
  }
}
