@import "../../../styles/breakpoints.scss";
@import "../../../styles/colors.scss";

.container {
  height: 100%;
}

.count {
  grid-column-start: 1;
  grid-row-start: 1;
  justify-self: right;
}

.dropdownWrapper {
  margin: 0 100px;
  display: grid;
  align-items: center;
  color: $green;
  text-align: center;

  @media (max-width: $desktop) {
    margin: 0 25px;
  }

  @media (max-width: $mobile-landscape) {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: 25px 0;
  }
}

.dropdowns {
  display: flex;
  justify-content: center;
  grid-column-start: 1;
  grid-row-start: 1;

  @media (max-width: $mobile-landscape) {
    flex-direction: column;
    align-items: center;
    width: 250px;
  }
}

.designation {
  width: 250px;
  margin-right: 1rem;
}

.state {
  width: 150px;
}

.designation,
.state {
  @media (max-width: $mobile-landscape) {
    width: 100%;
    margin-right: 0;
  }
}
