$green: #4b5e26;
$lightGreen: #a8c686;
$disabledGreen: #bec9a7;
$tan: #eae3d1;
$tanOutline: #d8d2bc;
$tanOutlineHover: #c5bc9c;
$white: #ffffff;
$black: #000000;
$gray: #bebebe;
$lightestGray: #fafafa;
$lightGray: #dee3e3;
$darkGray: #636363;
$error: #cb4c48;
