@import "../../../styles/colors.scss";
@import "../../../styles/breakpoints.scss";

.checkboxWrapper {
  display: block;
  position: relative;
  padding-left: 35px;
  padding-bottom: 12px;
  font-size: 18px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-column-break-inside: avoid; /* Chrome, Safari, Opera */
  page-break-inside: avoid; /* Firefox */
  break-inside: avoid; /* IE 10+ */

  &:hover {
    color: $green;
  }
}

/* Hide the browser's default checkbox */
.checkboxWrapper input {
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;
  height: 100%;
  z-index: 0;
}

.checkboxWrapper:hover input ~ .checkmark {
  background-color: $tanOutline;
}

.checkboxWrapper > input:focus + span {
  background-color: $tanOutline;
}

.checkboxWrapper input:checked ~ .checkmark {
  background-color: $green;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: $tan;
  border: 1px solid $green;
  z-index: -1;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkboxWrapper input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkboxWrapper .checkmark:after {
  left: 7px;
  top: 3px;
  width: 5px;
  height: 10px;
  border: solid $white;
  border-width: 0 2.5px 2.5px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
