@import "../../styles/colors.scss";
@import "../../styles/breakpoints.scss";

.menuIcon {
  margin-top: 5px;
  border-bottom: 5px solid transparent;

  &.active {
    border-bottom: 5px solid $green;
  }
}

.button {
  all: unset;
}

.avatar {
  border-radius: 50%;
  border: 1px solid $black;
}
