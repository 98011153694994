@import "../../styles/breakpoints.scss";
@import "../../styles/colors.scss";

.container {
  display: none;
  position: absolute;
  border: 1px solid $lightGray;
  background: $white;
  z-index: 1;
  top: 50px;
  right: 2.5rem;
  border-radius: 2px;
  box-shadow: -3px 5px 12px #00000008, 3px 4px 14px #00000008;

  .list {
    margin: 0 2rem;
    padding: 0;
  }

  .listItem {
    display: flex;
    align-items: center;
    list-style: none;
    height: 45px;
    border-bottom: 1px solid $lightGray;
    padding: 0 2rem;
    font-size: 1rem;

    &:hover {
      text-decoration: underline;
    }

    &:last-child {
      border: none;
    }

    a {
      text-decoration: none;
      color: $black;
    }

    button {
      background: transparent;
      border: none;
      font-size: 1rem;
    }
  }

  &.show {
    display: block;
  }
}
