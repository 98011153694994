@import "../../../styles/colors.scss";
@import "../../../styles/breakpoints.scss";

.mapContainer {
  position: relative;
  margin: 0 100px;
  overflow: hidden;
  border: 1px solid $tanOutline;
  @media (max-width: $desktop) {
    margin: 0 25px;
  }
}

// Map style
.state {
  fill: $tan;
  stroke: $tanOutline;
  stroke-width: 0.7;
  vector-effect: non-scaling-stroke;
  &.active {
    fill: $tanOutline;
    stroke: $tanOutlineHover;
    stroke-width: 2;
  }
  &.hover {
    stroke: $tanOutlineHover;
    stroke-width: 2;
  }
}

.copy {
  fill: $tanOutlineHover;
  font-weight: 600;
}

// Tree Map Markers

.treeContainer {
  cursor: default;

  &.selected {
    .tree {
      fill: $green;
    }
    .treeLinkText {
      fill: $white;
    }
  }
}

.tree {
  fill-rule: evenodd;
  fill: $lightGreen;
  stroke: $black;
  stroke-width: 20px;
  shape-rendering: geometricPrecision;

  &.hoverTree {
    fill: $green;
  }
}

.treeLinkText {
  font-size: 10px;
  font-weight: 600;
  text-anchor: middle;
  cursor: pointer;
  fill: $black;

  @media (max-width: $mobile-landscape) {
    font-size: 7px;
  }

  &.hoverTree {
    fill: $white;
  }

  &:hover {
    text-decoration: underline;
  }
}

// Tooltip Image
.imgContainer {
  display: flex;
  align-items: center;

  img {
    width: 50px;
    height: 50px;
    border-radius: 25px;
    border: 1px solid $black;
  }
}

.imgText {
  width: auto;
  margin-left: 10px;
  font-weight: normal;
  display: flex;
  flex-direction: column;
  justify-content: center;

  h1 {
    margin: 0;
    margin-bottom: 5px;
    font-size: 12px;
    color: $green;
  }

  .designation {
    padding-bottom: 2px;
  }
}

.tooltipBaseStyle {
  display: flex;
  max-width: 175px;
  width: auto;
  visibility: hidden;
  position: absolute;
  z-index: 1;
  height: auto;
  padding: 0.5rem;
  background: $white;
  border-radius: 10px;
  border: 1px solid $black;
  font-size: 12px;

  &::before {
    content: " ";
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -11px;
    border: 11px solid transparent;
    border-bottom-color: $black;
  }

  &::after {
    content: " ";
    position: absolute;
    bottom: 99%;
    left: 50%;
    margin-left: -10px;
    width: 0;
    height: 0;
    border: 10px solid transparent;
    border-bottom-color: $white;
  }
}

.buttons {
  position: absolute;
  right: 0;
  display: flex;
  flex-direction: column;
}

.plusMinus {
  display: flex;
  flex-direction: column;
  margin: 5px;
}

.plus,
.minus,
.home {
  all: unset;
  font-size: 12px;
  width: 26px;
  height: 26px;
  background-color: $darkGray;
  text-align: center;

  @media (max-width: $mobile-landscape) {
    width: 20px;
    height: 20px;
    font-size: 10px;
  }

  svg {
    fill: $white;
  }
}

.plus,
.minus {
  border-right: 1px solid $black;
  border-left: 1px solid $black;
}

.plus {
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  border-top: 1px solid $black;
}

.minus {
  border-top: 1px solid $black;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  border-bottom: 1px solid $black;
}

.home {
  margin: 5px;
  border: 1px solid $black;
  border-radius: 5px;
}
